// //01
//  $$$$$$$$$$$$$$$$$ ~~~ 01 Start ~~~ $$$$$$$$$$$$$$$$$
// export const BASE_URL = "http://localhost:8081";
// export const DOMAIN = "http://localhost:8081";
// export const BASE_URL = "https://dubaionlinebook01.com/dbone";
// export const DOMAIN = "https://dubaionlinebook01.com";

// $$$$$$$$$$$$$$$$$ ~~~ 01 End ~~~ $$$$$$$$$$$$$$$$$

// // //02
// //  $$$$$$$$$$$$$$$$$ ~~~ 02 Start ~~~ $$$$$$$$$$$$$$$$$
// // export const BASE_URL = "http://localhost:8081";
// // export const DOMAIN = "http://localhost:8081";
// export const BASE_URL = "https://dubaionlinebook02.com/dbtwo";
// export const DOMAIN = "https://dubaionlinebook02.com";

// // $$$$$$$$$$$$$$$$$ ~~~ 02 End ~~~ $$$$$$$$$$$$$$$$$

// // //03
// //  $$$$$$$$$$$$$$$$$ ~~~ 03 Start ~~~ $$$$$$$$$$$$$$$$$
// // export const BASE_URL = "http://localhost:8081";
// export const BASE_URL = "https://dubaionlinebook03.com/dbthree";
// export const DOMAIN = "https://dubaionlinebook03.com";

// // $$$$$$$$$$$$$$$$$ ~~~ 03 End ~~~ $$$$$$$$$$$$$$$$$

// // //04
// //  $$$$$$$$$$$$$$$$$ ~~~ 04 Start ~~~ $$$$$$$$$$$$$$$$$
// // export const BASE_URL = "http://localhost:8081";
// export const BASE_URL = "https://dubaionlinebook04.com/dbfour";
// export const DOMAIN = "https://dubaionlinebook04.com";

// // $$$$$$$$$$$$$$$$$ ~~~ 04 End ~~~ $$$$$$$$$$$$$$$$$

// // //05
// //  $$$$$$$$$$$$$$$$$ ~~~ 05 Start ~~~ $$$$$$$$$$$$$$$$$
// // export const BASE_URL = "http://localhost:8081";
export const BASE_URL = "https://dubaionlinebook05.com/dbfive";
export const DOMAIN = "https://dubaionlinebook05.com";

// // $$$$$$$$$$$$$$$$$ ~~~ 05 End ~~~ $$$$$$$$$$$$$$$$$

// // // //06
// // //  $$$$$$$$$$$$$$$$$ ~~~ 06 Start ~~~ $$$$$$$$$$$$$$$$$
// // export const BASE_URL = "http://localhost:8081";
// export const BASE_URL = "https://dubaionlinebook06.com/dbsix";
// export const DOMAIN = "https://dubaionlinebook06.com";

// // // $$$$$$$$$$$$$$$$$ ~~~ 06 End ~~~ $$$$$$$$$$$$$$$$$

// //07-customer_service
//  $$$$$$$$$$$$$$$$$ ~~~ 07-customer_service Start ~~~ $$$$$$$$$$$$$$$$$
// export const BASE_URL = "http://localhost:8081";
// export const DOMAIN = "http://localhost:8081";
// export const BASE_URL = "https://dubaicustomerservises.com/dbservices";
// export const DOMAIN = "https://dubaicustomerservises.com";

// $$$$$$$$$$$$$$$$$ ~~~ 07-customer_service End ~~~ $$$$$$$$$$$$$$$$$
